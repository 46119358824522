import { Request } from "../../Servidor/Request";

export class AgriculturalRegistrationRequest {
	private sgepcadastroagropecuario: number;
	private sgepprodutosagropecuarios: number;
	private quantidade: number;
	private area: number;
	private tipo: number;
	private sgepunidadeproducao: number;
	private dtinicio: Date;
	private dtfim: Date;
	private sgeppropriedade: number;

	constructor(sgepcadastroagropecuario: number, sgepprodutosagropecuarios: number, quantidade: number, area: number, tipo: number, sgepunidadeproducao: number, dtinicio: Date, dtfim: Date, sgeppropriedade: number) {
		this.sgepcadastroagropecuario = sgepcadastroagropecuario;
		this.sgepprodutosagropecuarios = sgepprodutosagropecuarios;
		this.quantidade = quantidade;
		this.area = area;
		this.tipo = tipo;
		this.sgepunidadeproducao = sgepunidadeproducao
		this.dtinicio = dtinicio
		this.dtfim = dtfim
		this.sgeppropriedade = sgeppropriedade;
	}

	/**
	 * Getter sgepcadastroagropecuario
	 * @return {number}
	 */
	public getSgepcadastroagropecuario(): number {
		return this.sgepcadastroagropecuario;
	}

	/**
	 * Getter sgepprodutosagropecuarios
	 * @return {number}
	 */
	public getSgepprodutosagropecuarios(): number {
		return this.sgepprodutosagropecuarios;
	}

	/**
	 * Getter quantidade
	 * @return {number}
	 */
	public getQuantidade(): number {
		return this.quantidade;
	}

	/**
	 * Getter areaplantada
	 * @return {number}
	 */
	public getAreaplantada(): number {
		return this.area;
	}

	/**
	 * Getter aptidaooutrosprodutos
	 * @return {boolean}
	 */
	public getTipo(): number {
		return this.tipo;
	}

	/**
	 * Getter sgeppropriedade
	 * @return {number}
	 */
	public getSgeppropriedade(): number {
		return this.sgeppropriedade;
	}
}
export class AgriculturalRegistrationStructRequest {
	private sgepcadastroagropecuario: number;

	constructor(sgepcadastroagropecuario: number) {
		this.sgepcadastroagropecuario = sgepcadastroagropecuario;
	}

	/**
	 * Getter sgepcadastroagropecuario
	 * @return {number}
	 */
	public getSgepcadastroagropecuario(): number {
		return this.sgepcadastroagropecuario;
	}
}

export class AgriculturalRegistrationInteractor {
	public fetchSave(
		request: AgriculturalRegistrationRequest
	): PromiseLike<Boolean> {
		return new Promise((res, rej) => {
			return new Request().requestPost("basico/cadastro-agropecuario/salvar", request).then(
				(rs) => {
					if (rs == undefined) {
						return rej({
							Mensagem: "Erro ao conectar!",
						});
					}

					let response = rs.Status == "Sucesso!";
					res(response);
				},
				(e) => {
					rej(e);
				}
			);
		});
	}

	public fetchDataOne(request: AgriculturalRegistrationStructRequest): PromiseLike<AgriculturalRegistrationRequest> {
		return new Promise((res, rej) => {
			return new Request().requestPost("basico/cadastro-agropecuario/buscar", request).then((rs) => {
				if (rs == undefined) {
					return rej({
						Mensagem: "Erro ao conectar!",
					});
				}
				res(rs.Resultado);
			},
				(e) => {
					rej(e);
				}
			);
		});
	}

	public fetchSelectClassification(): PromiseLike<any> {
		return new Promise((res, rej) => {
			return new Request().requestPost("basico/classificacao-tipo-produto-agropecuario/buscar-select", {}).then((rs) => {
				if (rs == undefined) {
					return rej({
						Mensagem: "Erro ao conectar",
					});
				}

				res(rs.Resultado);
			},
				(e) => {
					rej(e);
				}
			);
		});
	}

	public fetchSelectType(sgepclassificacaotpprodagropecuario?: number): PromiseLike<any> {
		return new Promise((res, rej) => {
			return new Request().requestPost("basico/tipo-produto-agropecuario/buscar-select", sgepclassificacaotpprodagropecuario ? { sgepclassificacaotpprodagropecuario } : {}).then((rs) => {
				if (rs == undefined) {
					return rej({
						Mensagem: "Erro ao conectar",
					});
				}

				res(rs.Resultado);
			},
				(e) => {
					rej(e);
				}
			);
		});
	}

	public fetchUnitProduction(): PromiseLike<any> {
		return new Promise((res, rej) => {
			return new Request().requestPost("basico/unidade-producao/buscar-select", {}).then((rs) => {
				if (rs == undefined) {
					return rej({
						Mensagem: "Erro ao conectar",
					});
				}

				res(rs.Resultado);
			},
				(e) => {
					rej(e);
				}
			);
		});
	}

	public fetchSelectProduct(sgeptpprodagropecuario?: number): PromiseLike<any> {
		return new Promise((res, rej) => {
			return new Request().requestPost("basico/produto-agropecuario/buscar-select", sgeptpprodagropecuario ? { sgeptpprodagropecuario } : {}).then(
				(rs) => {
					if (rs == undefined) {
						return rej({
							Mensagem: "Erro ao conectar",
						});
					}

					res(rs.Resultado);
				},
				(e) => {
					rej(e);
				}
			);
		});
	}
}
