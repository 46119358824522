import React from "react";
import ProtectedRoute from "../../Components/ProtectedRoute";
import { AgriculturalRegistrationViewController, AgriculturalRegistrationViewControllerDelegate, } from "../../Screen/AgriculturalRegistration/AgriculturalRegistrationViewController";
import { AgriculturalRegistrationViewModel } from "../../Screen/AgriculturalRegistration/AgriculturalRegistrationViewModel";
import { TableGenericViewController, TableGenericViewControllerDelegate, } from "../../Screen/TableGeneric/TableGenericViewController";
import { TableGenericViewModel } from "../../Screen/TableGeneric/TableGenericViewModel";
import { IndexCoordinatorDelegate } from "../IndexCoordinator";
import { TableGenericFilter } from "../TableGeneric/TableGenericInteractor";

export class AgriculturalRegistrationCoordinator implements AgriculturalRegistrationViewControllerDelegate, IndexCoordinatorDelegate, TableGenericViewControllerDelegate {
	getRoute(): JSX.Element[] {
		return [
			this.routeTableAgriculturalRegistration(),
			this.routeFormAgriculturalRegistration(),
		];
	}

	private routeTableAgriculturalRegistration(): JSX.Element {
		var viewModel = new TableGenericViewModel("gepcadastroagropecuario");
		return (
			<ProtectedRoute
				requiredPermissions={["prop.cad_agropecuario.*"]}
				path="/:base/propriedade/:idPropriedade/agricultural-registration"
				exact={true}
				component={(props: any) => {					
					var idFk = props.match.params.idPropriedade
					var filter = new TableGenericFilter('Propriedade', idFk)
					return (
						<TableGenericViewController
							permissions={{
								add: "prop.cad_agropecuario.adicionar",
								edit: "prop.cad_agropecuario.editar",
								delete: "prop.cad_agropecuario.excluir",
							}}
							navigation={props}
							viewModel={viewModel}
							delegate={this}
							filter={[filter]}
							title={"Cadastro Agropecuário"}
						/>
					);
				}}
			/>
		);
	}

	private routeFormAgriculturalRegistration(): JSX.Element {
		var viewModel = new AgriculturalRegistrationViewModel();
		return (
			<ProtectedRoute
				requiredPermissions={[
					"prop.cad_agropecuario.adicionar",
					"prop.cad_agropecuario.editar",
				]}
				path="/:base/propriedade/:idPropriedade/agricultural-registration/cad/:id"
				exact={true}
				component={(props: any) => {
					var idPk = props.match.params.id;
					var idPropriedade = props.match.params.idPropriedade;
					return (
						<AgriculturalRegistrationViewController
							navigation={props}
							viewModel={viewModel}
							delegate={this}
							idPrimary={idPk}
							idPropriedade={idPropriedade}
						/>
					);
				}}
			/>
		);
	}

	tapDimiss(navigation: any): void {
		navigation.history.goBack();
	}

	add(navigation: any, viewModel: TableGenericViewModel): void {
		if (viewModel.getTable() === "gepcadastroagropecuario") {
			var idPropriedade = navigation.match.params.idPropriedade;
			let database = localStorage.getItem('database')
			navigation.history.push(`/${database}/propriedade/${idPropriedade}/agricultural-registration/cad/0`);
			return;
		}
	}

	edit(navigation: any, idSelection: number, viewModel: TableGenericViewModel): void {
		if (viewModel.getTable() === "gepcadastroagropecuario") {
			var idPropriedade = navigation.match.params.idPropriedade;
			let database = localStorage.getItem('database')
			navigation.history.push(`/${database}/propriedade/${idPropriedade}/agricultural-registration/cad/${idSelection}`);
			return;
		}
	}
}
