import React from "react";
import ProtectedRoute from "../../Components/ProtectedRoute";
import { FarmClassificationTpProductViewController, FarmClassificationTpProductViewControllerDelegate } from "../../Screen/FarmClassificationTpProduct/FarmClassificationTpProductViewController";
import { FarmClassificationTpProductViewModel } from "../../Screen/FarmClassificationTpProduct/FarmClassificationTpProductViewModel";
import { NavOptions, TableGenericViewController, TableGenericViewControllerDelegate } from "../../Screen/TableGeneric/TableGenericViewController";
import { TableGenericViewModel } from "../../Screen/TableGeneric/TableGenericViewModel";
import { IndexCoordinatorDelegate } from "../IndexCoordinator";
import { FarmProductTypeViewController } from "../../Screen/FarmProductType/FarmProductTypeViewController";
import { FarmProductTypeViewModel } from "../../Screen/FarmProductType/FarmProductTypeViewModel";
import { TableGenericFilter } from "../TableGeneric/TableGenericInteractor";
import { FarmProductViewModel } from "../../Screen/FarmProduct/FarmProductViewModel";
import { FarmProductViewController } from "../../Screen/FarmProduct/FarmProductViewController";

export class FarmClassificationTpProductCoordinator implements FarmClassificationTpProductViewControllerDelegate, IndexCoordinatorDelegate, TableGenericViewControllerDelegate {
    getRoute(): JSX.Element[] {
        return [
            this.routeTableClassificationTpProduct(),
            this.routeFormClassificationTpProduct(),
            this.routeTableToTypeProduct(),
            this.routeFormTypeProduct(),
            this.routeTableToProduct(),
            this.routeFormToProduct()
        ]
    }

    private routeTableClassificationTpProduct(): JSX.Element {
        var arrayOptions = new Array<NavOptions>()
        arrayOptions.push({
            title: "Tipo de produto",
            action: (navigation: any, item: any, component: React.Component) => this.routerTypeProduct(navigation, item),
            isSelection: true
        })

        var viewModel = new TableGenericViewModel("gepclassificacaotpprodagropecuario")
        return (<ProtectedRoute requiredPermissions={['censo_agrop.classificacao.*']} path="/:base/classificacao-tipo-produto-agropecuario" exact={true} component={(props: any) => {
            return (<TableGenericViewController
                permissions={{
                    add: 'censo_agrop.classificacao.adicionar',
                    edit: 'censo_agrop.classificacao.editar',
                    delete: 'censo_agrop.classificacao.excluir',
                }}
                navigation={props}
                viewModel={viewModel}
                delegate={this}
                title={"Classificação dos Tipos de Produtos Agropecuários"}
                options={arrayOptions}
            />)
        }} />)
    }

    private routeFormClassificationTpProduct(): JSX.Element {
        var viewModel = new FarmClassificationTpProductViewModel()
        return (<ProtectedRoute requiredPermissions={['censo_agrop.classificacao.adicionar', 'censo_agrop.classificacao.editar']} path="/:base/classificacao-tipo-produto-agropecuario/cad/:id" exact={true} component={(props: any) => {
            var idPk = props.match.params.id

            return (<FarmClassificationTpProductViewController
                navigation={props}
                viewModel={viewModel}
                delegate={this}
                idPrimary={idPk}
            />)
        }} />)
    }

    private routeTableToTypeProduct(): JSX.Element {
        var arrayOptions = new Array<NavOptions>()
        arrayOptions.push({
            title: "Produto",
            action: (navigation: any, item: any, component: React.Component) => this.routerProduct(navigation, item),
            isSelection: true
        })
        var viewModel = new TableGenericViewModel("geptpprodagropecuario")
        return (<ProtectedRoute requiredPermissions={['censo_agrop.tipo_produto.*']} path="/:base/classificacao-tipo-produto-agropecuario/:id/tipo-produto-agropecuario/" exact={true} component={(props: any) => {
            var idFk = props.match.params.id
            var filter = new TableGenericFilter('Classificacao', idFk)
            return (<TableGenericViewController
                permissions={{
                    add: 'censo_agrop.tipo_produto.adicionar',
                    edit: 'censo_agrop.tipo_produto.editar',
                    delete: 'censo_agrop.tipo_produto.excluir',
                }}
                navigation={props}
                viewModel={viewModel}
                delegate={this}
                title={"Tipo de produto Agropecuários"}
                filter={[filter]}
                options={arrayOptions}
            />)
        }} />)
    }

    private routeFormTypeProduct(): JSX.Element {
        var viewModel = new FarmProductTypeViewModel()
        return (<ProtectedRoute requiredPermissions={['censo_agrop.tipo_produto.adicionar', 'censo_agrop.tipo_produto.editar']} path="/:base/classificacao-tipo-produto-agropecuario/:idClassTpProd/tipo-produto-agropecuario/cad/:id" exact={true} component={(props: any) => {
            var idClassTpProd = props.match.params.idClassTpProd
            var idPk = props.match.params.id
            return (<FarmProductTypeViewController
                navigation={props}
                viewModel={viewModel}
                delegate={this}
                idPrimary={idPk}
                idClassTpProd={idClassTpProd}
            />)
        }} />)
    }

    private routeTableToProduct(): JSX.Element {
        var viewModel = new TableGenericViewModel("gepprodutosagropecuarios")
        return (<ProtectedRoute requiredPermissions={['censo_agrop.produtos.*']} path="/:base/tipo-produto-agropecuario/:id/produto-agropecuario" exact={true} component={(props: any) => {
            var idFk = props.match.params.id
            var filter = new TableGenericFilter('Tipo', idFk)
            return (<TableGenericViewController
                permissions={{
                    add: 'censo_agrop.produtos.adicionar',
                    edit: 'censo_agrop.produtos.editar',
                    delete: 'censo_agrop.produtos.excluir',
                }}
                navigation={props}
                viewModel={viewModel}
                delegate={this}
                title={"Produtos Agropecuários"}
                filter={[filter]}
            />)
        }} />)
    }

    private routeFormToProduct(): JSX.Element {
        var viewModel = new FarmProductViewModel()
        return (<ProtectedRoute requiredPermissions={['censo_agrop.produtos.adicionar', 'censo_agrop.produtos.editar']} path="/:base/tipo-produto-agropecuario/:idProdType/produto-agropecuario/cad/:id" exact={true} component={(props: any) => {
            var idProdType = props.match.params.idProdType
            var idPk = props.match.params.id
            return (<FarmProductViewController
                navigation={props}
                viewModel={viewModel}
                delegate={this}
                idPrimary={idPk}
                idProdType={idProdType} />)
        }} />)
    }

    tapDimiss(navigation: any): void {
        navigation.history.goBack();
    }

    add(navigation: any, viewModel: TableGenericViewModel): void {
        var filter = viewModel.getFilter()
        if (viewModel.getTable() == "gepclassificacaotpprodagropecuario") {
            navigation.history.push(`classificacao-tipo-produto-agropecuario/cad/0`);
            return;
        }
        if (viewModel.getTable() == "geptpprodagropecuario" && filter) {
            var classificacao = filter[0].getValue()
            var database = localStorage.getItem('database')
            navigation.history.push(`/${database}/classificacao-tipo-produto-agropecuario/${classificacao}/tipo-produto-agropecuario/cad/0`);
            return;
        }
        if (viewModel.getTable() == "gepprodutosagropecuarios" && filter) {
            var database = localStorage.getItem('database')
            var tipo = filter[0].getValue()
            navigation.history.push(`/${database}/tipo-produto-agropecuario/${tipo}/produto-agropecuario/cad/0`)
        }
    }

    edit(navigation: any, idSelection: number, viewModel: TableGenericViewModel): void {
        var filter = viewModel.getFilter()
        if (viewModel.getTable() == "gepclassificacaotpprodagropecuario") {
            navigation.history.push(`classificacao-tipo-produto-agropecuario/cad/${idSelection}`);
            return;
        }
        if (viewModel.getTable() == "geptpprodagropecuario" && filter) {
            var database = localStorage.getItem('database')
            var classificacao = filter[0].getValue()
            navigation.history.push(`/${database}/classificacao-tipo-produto-agropecuario/${classificacao}/tipo-produto-agropecuario/cad/${idSelection}`)
        }
        if (viewModel.getTable() == "gepprodutosagropecuarios" && filter) {
            var database = localStorage.getItem('database')
            var tipo = filter[0].getValue()
            navigation.history.push(`/${database}/tipo-produto-agropecuario/${tipo}/produto-agropecuario/cad/${idSelection}`)
        }
    }

    private routerTypeProduct(navigation: any, item: any) {
        var database = localStorage.getItem('database')
        navigation.history.push(`/${database}/classificacao-tipo-produto-agropecuario/${item}/tipo-produto-agropecuario/`);
    }
    private routerProduct(navigation: any, item: any) {
        var database = localStorage.getItem('database')
        navigation.history.push(`/${database}/tipo-produto-agropecuario/${item}/produto-agropecuario/`);
    }
}