import React from 'react';
import { IndexCoordinatorDelegate } from "../IndexCoordinator";
import { PropertyViewController, PropertyViewControllerDelegate } from '../../Screen/Property/PropertyViewController';
import { PropertyViewModel } from '../../Screen/Property/PropertyViewModel';
import { TableGenericViewControllerDelegate, TableGenericViewController, NavOptions } from "../../Screen/TableGeneric/TableGenericViewController";
import { TableGenericViewModel } from "../../Screen/TableGeneric/TableGenericViewModel";
import { TableGenericFilter } from '../TableGeneric/TableGenericInteractor';
import ProtectedRoute from '../../Components/ProtectedRoute';

export class PropertyCoordinator implements IndexCoordinatorDelegate, PropertyViewControllerDelegate, TableGenericViewControllerDelegate {

    private copyProp: boolean
    private typeProp: string
    constructor() {
        this.copyProp = false
        this.typeProp = ""
    }

    getRoute(): JSX.Element[] {
        return [
            this.routeForm(),
            this.routeTable()
        ]
    }

    private routeForm(): JSX.Element {
        var viewModel = new PropertyViewModel()
        return (<ProtectedRoute requiredPermissions={['geppropriedade.*']} path="/:base/ponto/:idPoint/propriedade/tipo/:type/cad/:id" exact={true} component={(props: any) => {
            var idPk = props.match.params.id
            var idPoint = props.match.params.idPoint
            var typePoint = props.match.params.type
            return (<PropertyViewController
                navigation={props}
                viewModel={viewModel}
                delegate={this}
                idPrimary={idPk}
                idPoint={idPoint}
                copyProp={this.copyProp}
                typeProp={typePoint}
            />)
        }} />)
    }

    private routeTable(): JSX.Element {
        var viewModel = new TableGenericViewModel("geppropriedade")
        return (<ProtectedRoute requiredPermissions={['geppropriedade.*']} path="/:base/ponto/:idPoint/propriedade/tipo/:type" exact={true} component={(props: any) => {
            var typePoint = props.match.params.type
            var idPoint = props.match.params.idPoint
            var filter = new TableGenericFilter('Ponto', idPoint)
            this.typeProp = typePoint

            var arrayOptions = new Array<NavOptions>()
            arrayOptions.push({
                title: "Arquivos Digitais (Documentos)",
                action: (navigation: any, item: any, component: React.Component) => this.tapDocuments(navigation, item),
                isSelection: true
            })

            arrayOptions.push({
                title: "Duplicar histórico",
                action: (navigation: any, item: any, component: React.Component) => this.tapCopyProp(navigation, item),
                isSelection: true
            })

            if (typePoint != 'D') {
                arrayOptions.push({
                    title: "Edificações",
                    action: (navigation: any, item: any, component: React.Component) => this.tapBuildings(navigation, item),
                    isSelection: true
                })
            }

            arrayOptions.push({
                title: "Processo Administrativo",
                action: (navigation: any, item: any, component: React.Component) => this.tapAdminProcess(navigation, item),
                isSelection: true
            })

            arrayOptions.push({
                title: "Visitas",
                action: (navigation: any, item: any, component: React.Component) => this.tapVisit(navigation, item),
                isSelection: true
            })

            arrayOptions.push({
                title: "Cadastros Agropecuário",
                action: (navigation: any, item: any) => {
                    this.tapAgriculturalRegistration(navigation, item)
                },
                isSelection: true
            })

            return (<TableGenericViewController
                navigation={props}
                viewModel={viewModel}
                delegate={this}
                filter={[filter]}
                options={arrayOptions}
                title={"Histórico da Propriedade"}
            />)
        }} />)
    }

    add(navigation: any, viewModel: TableGenericViewModel): void {
        var filter = viewModel.getFilter()
        this.copyProp = false

        if (filter) {
            var database = localStorage.getItem('database')
            navigation.history.push(`/${database}/ponto/${filter[0].getValue()}/propriedade/tipo/${this.typeProp}/cad/0`);
        }
    }

    edit(navigation: any, idSelection: number, viewModel: TableGenericViewModel): void {
        var filter = viewModel.getFilter()
        this.copyProp = false

        if (filter) {
            var database = localStorage.getItem('database')
            navigation.history.push(`/${database}/ponto/${filter[0].getValue()}/propriedade/tipo/${this.typeProp}/cad/${idSelection}`);
        }
    }

    tapCopyProp(navigation: any, item: any): void {
        this.copyProp = true
        navigation.history.push(`${this.typeProp}/cad/${item}`);
    }

    tapDimiss(navigation: any): void {
        navigation.history.goBack();
    }

    tapDocuments(navigation: any, item: any): void {
        var database = localStorage.getItem('database')
        navigation.history.push(`/${database}/propriedade/${item}/arquivos`);
    }

    tapBuildings(navigation: any, item: any): void {
        var database = localStorage.getItem('database')
        navigation.history.push(`/${database}/propriedade/tipo/${this.typeProp}/${item}/edificacoes`);
    }

    tapVisit(navigation: any, item: any): void {
        var database = localStorage.getItem('database')
        navigation.history.push(`/${database}/propriedade/${item}/visita`);
    }

    tapAgriculturalRegistration(navigation: any, item: any): void {
        var database = localStorage.getItem('database')
        navigation.history.push(`/${database}/propriedade/${item}/agricultural-registration`);
    }

    tapAdminProcess(navigation: any, item: any): void {
        var database = localStorage.getItem('database')
        navigation.history.push(`/${database}/propriedade/${item}/processo-administrativo`);
    }
}