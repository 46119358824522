import { Request } from "../../Servidor/Request";

export class UnitProductionRequest {
    private sgepunidadeproducao: number;
    private nome: string;

    constructor (sgepunidadeproducao:number, nome: string){
        this.sgepunidadeproducao = sgepunidadeproducao;
        this.nome = nome;
    }

    /**
     * Getter sgepunidadeproducao
     * @return {number}
     */
    public getSgeptpprodagropecuario(): number {
        return this.sgepunidadeproducao;
    }

    /**
     * Getter nome
     * @return {string}
     */
    public getNome(): string {
        return this.nome;
    }
}
export class UnitProductionStructRequest {
    private sgepunidadeproducao: number;

    constructor (sgepunidadeproducao: number){
        this.sgepunidadeproducao = sgepunidadeproducao;
    }

    /**
     * Getter sgepunidadeproducao
     * @return {number}
     */
    public getSgepunidadeproducao(): number {
        return this.sgepunidadeproducao;
    }
}

export class UnitProductionInteractor {
    public fetchSave(request: UnitProductionRequest): PromiseLike<Boolean>{
        return new Promise ((res,rej) => {
            return new Request().requestPost("basico/unidade-producao/salvar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                      Mensagem:"Erro ao conectar!"  
                    })
                }

                let response = rs.Status == "Sucesso!"
                res(response) 
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDataOne(request: UnitProductionStructRequest): PromiseLike<UnitProductionRequest>{
        return new Promise ((res, rej) => {
            return new Request().requestPost("basico/unidade-producao/buscar", request).then((rs) => {
                if(rs == undefined) {
                    return rej({
                        Mensagem:"Erro ao conectar!"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
}
