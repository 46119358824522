import { AgriculturalRegistrationRequest, AgriculturalRegistrationStructRequest, AgriculturalRegistrationInteractor, } from "../../Coordinator/AgriculturalRegistration/AgriculturalRegistrationInteractor";

export class AgriculturalRegistrationViewModel {
	private interactor: AgriculturalRegistrationInteractor;

	constructor() {
		this.interactor = new AgriculturalRegistrationInteractor();
	}

	public fetchSave(obj: any): PromiseLike<any> {
		var request = new AgriculturalRegistrationRequest(
			obj["sgepcadastroagropecuario"] ? parseInt(obj["sgepcadastroagropecuario"]) : 0,
			obj["sgepprodutosagropecuarios"] ? parseInt(obj["sgepprodutosagropecuarios"]) : 0,
			obj["quantidade"] ? parseFloat(obj["quantidade"]) : 0,
			obj["area"] ? parseFloat(obj["area"]) : 0,
			obj["tipo"] ? obj["tipo"] : false,
			obj["sgepunidadeproducao"] ? parseInt(obj["sgepunidadeproducao"]) : 0,
			obj["dtinicio"] ? obj["dtinicio"] : '',
			obj["dtfim"] ? obj['dtfim'] : '',
			obj["sgeppropriedade"] ? parseInt(obj["sgeppropriedade"]) : 0
		);

		return new Promise((res, rej) => {
			this.interactor.fetchSave(request).then((result) => {
				return res(true);
			}, (e) => {
				return rej(e);
			}
			);
		});
	}

	public fetchDataOne(obj: any): PromiseLike<any> {
		var request = new AgriculturalRegistrationStructRequest(
			obj["sgepcadastroagropecuario"] ? parseInt(obj["sgepcadastroagropecuario"]) : 0
		);

		return new Promise((res, rej) => {
			this.interactor.fetchDataOne(request).then((result) => {
				return res(result);
			}, (e) => {
				return rej(e);
			}
			);
		});
	}

	public fetchSelectClassification(): PromiseLike<any> {
		return new Promise((res, rej) => {
			this.interactor.fetchSelectClassification().then((result) => {
				return res(result);
			}, (e) => {
				return rej(e);
			}
			);
		});
	}

	public fetchSelectType(sgepclassificacaotpprodagropecuario?: number): PromiseLike<any> {
		return new Promise((res, rej) => {
			this.interactor.fetchSelectType(sgepclassificacaotpprodagropecuario).then((result) => {
				return res(result);
			}, (e) => {
				return rej(e);
			}
			);
		});
	}

	public fetchUnitProduction(): PromiseLike<any> {
		return new Promise((res, rej) => {
			this.interactor.fetchUnitProduction().then((result) => {
				return res(result);
			}, (e) => {
				return rej(e);
			}
			);
		});
	}

	public fetchSelectProduct(sgeptpprodagropecuario?: number): PromiseLike<any> {
		return new Promise((res, rej) => {
			this.interactor.fetchSelectProduct(sgeptpprodagropecuario).then((result) => {
				return res(result);
			}, (e) => {
				return rej(e);
			}
			);
		});
	}
}
