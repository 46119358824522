import { FarmClassificationTpProductInteractor, FarmClassificationTpProductRequest, FarmClassificationTpProductStructRequest } from "../../Coordinator/FarmClassificationTpProduct/FarmClassificationTpProductInteractor";

export class FarmClassificationTpProductViewModel {
    private interactor: FarmClassificationTpProductInteractor;

    constructor() {
        this.interactor = new FarmClassificationTpProductInteractor();
    }

    public fetchSave(obj: any): PromiseLike<any> {
        var request = new FarmClassificationTpProductRequest(
            obj["sgepclassificacaotpprodagropecuario"] ? parseInt(obj["sgepclassificacaotpprodagropecuario"]) : 0,
            obj["nome"] ? obj["nome"] : "",
        )
        
        return new Promise((res, rej) => {  
            this.interactor.fetchSave(request).then((result) => {
                return res(true)
            }, e => {
                return rej(e)
            });
        })
    }

    public fetchDataOne(obj: any): PromiseLike<any> {
        var request = new FarmClassificationTpProductStructRequest(
            obj["sgepclassificacaotpprodagropecuario"] ? parseInt(obj["sgepclassificacaotpprodagropecuario"]) : 0
        )
        
        return new Promise((res, rej) => {
            this.interactor.fetchDataOne(request).then((result) => {
                return res(result)
            }, e => {
                return rej(e)
            });
        })
    }
}