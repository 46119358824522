import React from "react";
import ProtectedRoute from "../../Components/ProtectedRoute";
import { UnitProductionViewController, UnitProductionViewControllerDelegate } from "../../Screen/UnitProduction/UnitProductionViewController";
import { UnitProductionViewModel } from "../../Screen/UnitProduction/UnitProductionViewModel";
import { TableGenericViewController, TableGenericViewControllerDelegate } from "../../Screen/TableGeneric/TableGenericViewController";
import { TableGenericViewModel } from "../../Screen/TableGeneric/TableGenericViewModel";
import { IndexCoordinatorDelegate } from "../IndexCoordinator";

export class UnitProductionCoordinator implements UnitProductionViewControllerDelegate, IndexCoordinatorDelegate, TableGenericViewControllerDelegate {
    getRoute(): JSX.Element[] {
        return [
            this.routeTableUnitProductionProduct(),
            this.routeFormUnitProductionProduct(),
        ]
    }

    private routeTableUnitProductionProduct(): JSX.Element {
        var viewModel = new TableGenericViewModel("gepunidadeproducao")
        return (<ProtectedRoute requiredPermissions={['censo_agrop.unidade_prod.*']} path="/:base/unidade-producao" exact={true} component={(props: any) => {
            return (<TableGenericViewController
                permissions={{
                    add: 'censo_agrop.unidade_prod.adicionar',
                    edit: 'censo_agrop.unidade_prod.editar',
                    delete: 'censo_agrop.unidade_prod.excluir',
                }}
                navigation={props}
                viewModel={viewModel}
                delegate={this}
                title={"Unidade de Produção"}
            />)
        }} />)
    }

    private routeFormUnitProductionProduct(): JSX.Element {
        var viewModel = new UnitProductionViewModel()
        return (<ProtectedRoute requiredPermissions={['censo_agrop.unidade_prod.adicionar', 'censo_agrop.unidade_prod.editar']} path="/:base/unidade-producao/cad/:id" exact={true} component={(props: any) => {
            var idPk = props.match.params.id
            return (<UnitProductionViewController
                navigation={props}
                viewModel={viewModel}
                delegate={this}
                idPrimary={idPk}
            />)
        }} />)
    }

    tapDimiss(navigation: any): void {
        navigation.history.goBack();
    }

    add(navigation: any, viewModel: TableGenericViewModel): void {
        if (viewModel.getTable() == "gepunidadeproducao") {
            navigation.history.push(`unidade-producao/cad/0`);
            return;
        }
    }

    edit(navigation: any, idSelection: number, viewModel: TableGenericViewModel): void {
        if (viewModel.getTable() == "gepunidadeproducao") {
            navigation.history.push(`unidade-producao/cad/${idSelection}`);
            return;
        }
    }
}