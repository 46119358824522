import { Request } from "../../Servidor/Request";

export class FarmProductTypeRequest {
    private sgeptpprodagropecuario: number;
    private nome: string;
    private sgepclassificacaotpprodagropecuario: number;

    constructor(sgeptpprodagropecuario: number, nome: string, sgepclassificacaotpprodagropecuario: number) {
        this.sgeptpprodagropecuario = sgeptpprodagropecuario;
        this.nome = nome;
        this.sgepclassificacaotpprodagropecuario = sgepclassificacaotpprodagropecuario;
    }

    /**
     * Getter sgeptpprodagropecuario
     * @return {number}
     */
    public getSgeptpprodagropecuario(): number {
        return this.sgeptpprodagropecuario;
    }

    /**
     * Getter nome
     * @return {string}
     */
    public getNome(): string {
        return this.nome;
    }
    public getClassificacao(): number {
        return this.sgepclassificacaotpprodagropecuario;
    }
}
export class FarmProductTypeStructRequest {
    private sgeptpprodagropecuario: number;

    constructor(sgeptpprodagropecuario: number) {
        this.sgeptpprodagropecuario = sgeptpprodagropecuario;
    }

    /**
     * Getter sgeptpprodagropecuario
     * @return {number}
     */
    public getSgeptpprodagropecuario(): number {
        return this.sgeptpprodagropecuario;
    }
}

export class FarmProductTypeInteractor {
    public fetchSave(request: FarmProductTypeRequest): PromiseLike<Boolean> {
        return new Promise((res, rej) => {
            return new Request().requestPost("basico/tipo-produto-agropecuario/salvar", request).then((rs) => {
                if (rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar!"
                    })
                }

                let response = rs.Status == "Sucesso!"
                res(response)
            }, e => {
                rej(e);
            });
        });
    }

    public fetchDataOne(request: FarmProductTypeStructRequest): PromiseLike<FarmProductTypeRequest> {
        return new Promise((res, rej) => {
            return new Request().requestPost("basico/tipo-produto-agropecuario/buscar", request).then((rs) => {
                if (rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar!"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
    public fetchSelectClassification(): PromiseLike<any> {
        return new Promise((res, rej) => {
            return new Request().requestPost("basico/classificacao-tipo-produto-agropecuario/buscar-select", {}).then((rs) => {
                if (rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }

                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
}
