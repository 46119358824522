import React from "react";
import { FarmProductViewModel } from "./FarmProductViewModel";
import { NavBarComponente } from "../../Components/NavBarComponente";
import { Container, Row, Image, Col, Form, Button } from 'react-bootstrap';
import fechar from "../../Imagens/close-24.png";
import salvar from "../../Imagens/save-16.png";

import "../../Estilo/Form.css";
import { FormEvent } from "../../ScreenGeneric/Models/FormEvent";
import { ToastContainer, toast } from "react-toastify";
import { LoaderComponente } from "../../Components/LoaderComponente";
import Select from 'react-select';

interface MyProps {
    navigation: any
    delegate: FarmProductViewControllerDelegate
    viewModel: FarmProductViewModel
    idPrimary: number
    idProdType?: number
}

export interface FarmProductViewControllerDelegate {
    tapDimiss(navigation: any): void
}

export class FarmProductViewController extends React.Component<MyProps> {

    private formEvent: FormEvent
    state = {
        isLoading: false,
        isDisabled: false,
        classification: new Array<any>(),
        productType: new Array<any>(),
        json: {}
    }

    constructor(props: any) {
        super(props)
        this.formEvent = new FormEvent(this)
    }

    componentDidMount() {
        this.loadClassification()
        this.checkDisable()
        if (this.props.idPrimary > 0) {
            this.loadProductType()
            this.loadStruct()
        }
    }

    render() {
        var json = this.state.json as any
        return (
            <div>
                <NavBarComponente propNav={this.props.navigation} />
                <div>
                    <Container fluid={true}>
                        <Row>
                            <Col>
                                <div className="title">
                                    <h3>Produtos Agropecuários</h3>
                                </div>
                                <div className="buttonClose">
                                    <Image className="imageClose"
                                        onClick={(e: any) => this.props.delegate.tapDimiss(this.props.navigation)}
                                        src={fechar} />
                                </div>
                            </Col>
                        </Row>
                        <Form className="containerForm">
                            <Row>
                                <Col lg="4" xs="12" className="bottom10 hide">
                                    <Form.Label>Sequencial</Form.Label>
                                    <Form.Control
                                        id="sgepprodutosagropecuarios"
                                        defaultValue={json['sgepprodutosagropecuarios'] ? json['sgepprodutosagropecuarios'] : ''}
                                        onChange={(e: any) => this.formEvent.onChange("inteiro", e.target)}
                                    />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label className="isRequired">Nome</Form.Label>
                                    <Form.Control
                                        id="nome"
                                        defaultValue={json['nome'] ? json['nome'] : ''}
                                        onChange={(e: any) => this.formEvent.onChange("varchar", e.target)}
                                        placeholder="Insira o Nome"
                                    />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label className={`${!this.props.idPrimary && 'isRequired'}`}>
                                        Classificação
                                    </Form.Label>
                                    <Select
                                        id="sgepclassificacaotpprodagropecuario"
                                        isDisabled={this.state.isDisabled}
                                        value={this.formEvent.onValueSelect("select", "sgepclassificacaotpprodagropecuario", this.state.classification)}
                                        onChange={(e: any) => {
                                            this.formEvent.onChange("select", e, "sgepclassificacaotpprodagropecuario");
                                            this.loadProductType(parseInt(e.value));
                                        }}
                                        closeMenuOnSelect={true}
                                        placeholder={"Selecione"}
                                        options={this.state.classification}
                                        isMulti={false}
                                    />
                                </Col>

                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label className="isRequired">Tipo de Produto</Form.Label>
                                    <Select
                                        id="sgeptpprodagropecuario"
                                        isDisabled={this.state.isDisabled || !this.state.productType.length}
                                        value={this.formEvent.onValueSelect('select', 'sgeptpprodagropecuario', this.state.productType)}
                                        onChange={(e: any) => this.formEvent.onChange("select", e, "sgeptpprodagropecuario")}
                                        closeMenuOnSelect={true}
                                        placeholder={"Selecione"}
                                        options={this.state.productType}
                                        isMulti={false}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" style={{ marginTop: 20 }}>
                                    <Button className="buttonSave" type="button" onClick={(e: any) => this.save()} variant="primary">
                                        <Image className="imageSave"
                                            src={salvar} />
                                        Salvar
                                    </Button>
                                    <Button onClick={(e: any) => this.props.delegate.tapDimiss(this.props.navigation)} className="buttonCancel" variant="link">Cancelar</Button>
                                </Col>
                            </Row>
                        </Form>
                        <ToastContainer />
                    </Container>
                </div>
                <LoaderComponente show={this.state.isLoading} hide={() => this.setState({ isLoading: this.state.isLoading })} />
            </div>
        )
    }
    private loadClassification() {
        this.setState({ isLoading: true });
        this.props.viewModel.fetchSelectClassification().then((rs) => {
            var result = rs as Array<any>;
            var array = Array<any>();

            result.forEach((item) => {
                array.push({ value: item.id, label: item.value, check: false });
            })
            this.setState({
                classification: array,
                isLoading: false,
            });
        });
    }

    private loadProductType(sgepclassificacaotpprodagropecuario?: number) {
        this.setState({ isLoading: true })
        this.props.viewModel.fetchSelectType(sgepclassificacaotpprodagropecuario).then((rs) => {
            var result = rs as Array<any>
            var array = Array<any>()

            result.forEach((item) => {
                array.push({ value: item.id, label: item.value, check: false });
            })
            this.setState({
                productType: array,
                isLoading: false
            })
        });
    }

    private checkDisable() {
        var json = this.state.json as any
        if (this.props.idProdType != undefined) {
            json['sgeptpprodagropecuario'] = this.props.idProdType
            this.setState({ isDisabled: true, json: json })
        }
    }

    private save() {
        var json = this.state.json as any

        if (!json["nome"]) {
            this.showFieldsEmpty("Nome é obrigatório!")
            return
        }
        if (!json["sgeptpprodagropecuario"]) {
            this.showFieldsEmpty("Tipo de produto é obrigatório!")
            return
        }


        if (this.props.idProdType != undefined) {
            json['sgeptpprodagropecuario'] = this.props.idProdType
        }

        this.setState({ isLoading: true })

        this.props.viewModel.fetchSave(json).then(() => {
            toast.success("Registro incluído com sucesso!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });


            setTimeout(() => {
                this.props.delegate.tapDimiss(this.props.navigation);
            }, 1000);
        }, e => {
            toast.error(JSON.stringify(e), {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        })

        this.setState({ isLoading: false })
    }

    private showFieldsEmpty(message: string) {
        toast.error(message, {
            position: toast.POSITION.BOTTOM_RIGHT
        });
    }

    private loadStruct() {
        var json = this.state.json as any
        json['sgepprodutosagropecuarios'] = this.props.idPrimary

        this.setState({ isLoading: true })
        this.props.viewModel.fetchDataOne(json).then((rs) => {
            var resultado = rs as Array<any>
            if (resultado.length > 0) {
                this.setState({
                    isLoading: false,
                    json: resultado[0]
                })
                return
            }

            this.setState({ isLoading: false })
        }, e => {
            toast.error(JSON.stringify(e), {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        });
    }
}