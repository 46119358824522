import React from "react";
import { AgriculturalRegistrationViewModel } from "./AgriculturalRegistrationViewModel";
import { NavBarComponente } from "../../Components/NavBarComponente";
import { Container, Row, Image, Col, Form, Button } from "react-bootstrap";
import fechar from "../../Imagens/close-24.png";
import salvar from "../../Imagens/save-16.png";

import "../../Estilo/Form.css";
import { FormEvent } from "../../ScreenGeneric/Models/FormEvent";
import { ToastContainer, toast } from "react-toastify";
import { LoaderComponente } from "../../Components/LoaderComponente";
import Select from "react-select";

interface MyProps {
	navigation: any;
	delegate: AgriculturalRegistrationViewControllerDelegate;
	viewModel: AgriculturalRegistrationViewModel;
	idPrimary: number;
	idPropriedade?: number;
}

export interface AgriculturalRegistrationViewControllerDelegate {
	tapDimiss(navigation: any): void;
}

export class AgriculturalRegistrationViewController extends React.Component<MyProps> {
	private formEvent: FormEvent;
	state = {
		isLoading: false,
		isDisabled: false,
		classification: new Array<any>(),
		productType: new Array<any>(),
		product: new Array<any>(),
		unitProduction: new Array<any>(),
		aptidao: [{ value: 1, label: "Produção", check: true }, { value: 2, label: "Aptidão", check: true }],
		json: {},
	};

	constructor(props: any) {
		super(props);
		this.formEvent = new FormEvent(this);
	}

	componentDidMount() {
		this.loadClassification();
		this.loadUnitProduction();
		if (this.props.idPrimary > 0) {
			this.loadProduct();
			this.loadProductType();
			this.loadStruct();
		}

	}

	render() {
		var json = this.state.json as any;
		return (
			<div>
				<NavBarComponente propNav={this.props.navigation} />
				<div>
					<Container fluid={true}>
						<Row>
							<Col>
								<div className="title">
									<h3>Cadastro Agropecuário</h3>
								</div>
								<div className="buttonClose">
									<Image
										className="imageClose"
										onClick={(e: any) =>
											this.props.delegate.tapDimiss(this.props.navigation)
										}
										src={fechar}
									/>
								</div>
							</Col>
						</Row>
						<Form className="containerForm">
							<Row>
								<Col lg="4" xs="12" className="bottom10 hide">
									<Form.Label>Sequencial</Form.Label>
									<Form.Control
										id="sgepprodutosagropecuarios"
										defaultValue={
											json["sgepprodutosagropecuarios"]
												? json["sgepprodutosagropecuarios"]
												: ""
										}
										onChange={(e: any) =>
											this.formEvent.onChange("inteiro", e.target)
										}
									/>
								</Col>
								<Col lg="4" xs="12" className="bottom10">
									<Form.Label className={`${this.props.idPrimary == 0 && 'isRequired'}`}>
										Classificação
									</Form.Label>
									<Select
										id="sgepclassificacaotpprodagropecuario"
										isDisabled={this.state.isDisabled}
										value={this.formEvent.onValueSelect("select", "sgepclassificacaotpprodagropecuario", this.state.classification)}
										onChange={(e: any) => {
											this.formEvent.onChange("select", e, "sgepclassificacaotpprodagropecuario");
											this.loadProductType(parseInt(e.value));
										}}
										closeMenuOnSelect={true}
										placeholder={"Selecione"}
										options={this.state.classification}
										isMulti={false}
									/>
								</Col>
								<Col lg="4" xs="12" className="bottom10">
									<Form.Label className={`${this.props.idPrimary == 0 && 'isRequired'}`}>
										Tipo de Produto
									</Form.Label>
									<Select
										id="sgeptpprodagropecuario"
										isDisabled={this.state.isDisabled || !this.state.productType.length}
										value={this.formEvent.onValueSelect("select", "sgeptpprodagropecuario", this.state.productType)}
										onChange={(e: any) => {
											this.formEvent.onChange("select", e, "sgeptpprodagropecuario");
											this.loadProduct(parseInt(e.value));
										}}
										closeMenuOnSelect={true}
										placeholder={"Selecione"}
										options={this.state.productType}
										isMulti={false}
									/>
								</Col>
								{this.state.product && (
									<Col lg="4" xs="12" className="bottom10">
										<Form.Label className="isRequired">Produto</Form.Label>
										<Select
											id="sgepprodutosagropecuarios"
											isDisabled={this.state.isDisabled || !this.state.product.length}
											value={this.formEvent.onValueSelect("select", "sgepprodutosagropecuarios", this.state.product)}
											onChange={(e: any) => this.formEvent.onChange("select", e, "sgepprodutosagropecuarios")}
											closeMenuOnSelect={true}
											placeholder={"Selecione"}
											options={this.state.product}
											isMulti={false}
										/>
									</Col>
								)}
								<Col lg="4" xs="12" className="bottom10">
									<Form.Label className="isRequired">Área (ha)</Form.Label>
									<Form.Control
										id="area"
										type="number"
										defaultValue={
											json["area"] ? json["area"] : ""
										}
										onChange={(e: any) =>
											this.formEvent.onChange("varchar", e.target)
										}
										placeholder="Insira a área"
									/>
								</Col>
								<Col lg="4" xs="12" className="bottom10">
									<Form.Label className="isRequired">Quantidade</Form.Label>
									<Form.Control
										id="quantidade"
										type="number"
										defaultValue={json["quantidade"] ? json["quantidade"] : ""}
										onChange={(e: any) =>
											this.formEvent.onChange("varchar", e.target)
										}
										placeholder="Insira a quantidade"
									/>
								</Col>
								<Col lg="4" xs="12" className="bottom10">
									<Form.Label className="isRequired">
										Unidade de Produção
									</Form.Label>
									<Select
										id="sgepunidadeproducao"
										isDisabled={this.state.isDisabled}
										value={this.formEvent.onValueSelect("select", "sgepunidadeproducao", this.state.unitProduction)}
										onChange={(e: any) => { this.formEvent.onChange("select", e, "sgepunidadeproducao"); }}
										closeMenuOnSelect={true}
										placeholder={"Selecione"}
										options={this.state.unitProduction}
										isMulti={false}
									/>
								</Col>
								<Col lg="4" xs="12" className="bottom10">
									<Form.Label className="isRequired">Tipo</Form.Label>
									<Select
										id="tipo"
										isDisabled={this.state.isDisabled}
										value={this.formEvent.onValueSelect("select", "tipo", this.state.aptidao)}
										onChange={(e: any) => this.formEvent.onChange("select", e, "tipo")}
										defaultValue={json["tipo"]}
										closeMenuOnSelect={true}
										placeholder={"Selecione"}
										options={this.state.aptidao}
										isMulti={false}
									/>
								</Col>
								<Col lg="4" xs="12" className="bottom10">
									<Form.Label className="isRequired">Data de Início</Form.Label>
									<Form.Control
										id="dtinicio"
										type="date"
										defaultValue={json["dtinicio"] ? json["dtinicio"].split("T")[0] : ""}
										onChange={(e: any) =>
											this.formEvent.onChange("varchar", e.target)
										}
										placeholder="Selecione a Data de Início"
									/>
								</Col>
								<Col lg="4" xs="12" className="bottom10">
									<Form.Label className="isRequired">Data de Fim</Form.Label>
									<Form.Control
										id="dtfim"
										type="date"
										defaultValue={json["dtfim"] ? json["dtfim"].split("T")[0] : ""}
										onChange={(e: any) =>
											this.formEvent.onChange("varchar", e.target)
										}
										placeholder="Selecione a Data de Fim"
									/>
								</Col>
							</Row>
							<Row>
								<Col xs="12" style={{ marginTop: 20 }}>
									<Button
										className="buttonSave"
										type="button"
										onClick={(e: any) => this.save()}
										variant="primary"
									>
										<Image className="imageSave" src={salvar} />
										Salvar
									</Button>
									<Button
										onClick={(e: any) =>
											this.props.delegate.tapDimiss(this.props.navigation)
										}
										className="buttonCancel"
										variant="link"
									>
										Cancelar
									</Button>
								</Col>
							</Row>
						</Form>
						<ToastContainer />
					</Container>
				</div>
				<LoaderComponente
					show={this.state.isLoading}
					hide={() => this.setState({ isLoading: this.state.isLoading })}
				/>
			</div>
		);
	}

	private loadClassification() {
		this.setState({ isLoading: true });
		this.props.viewModel.fetchSelectClassification().then((rs) => {
			var result = rs as Array<any>;
			var array = Array<any>();

			result.forEach((item) => {
				array.push({ value: item.id, label: item.value, check: false });
			})
			this.setState({
				classification: array,
				isLoading: false,
			});
		});
	}

	private loadProductType(sgepclassificacaotpprodagropecuario?: number) {
		this.setState({ isLoading: true });
		this.props.viewModel.fetchSelectType(sgepclassificacaotpprodagropecuario).then((rs) => {
			var result = rs as Array<any>;
			var array = Array<any>();

			result.forEach((item) => {
				array.push({ value: item.id, label: item.value, check: false });
			})

			this.setState({
				productType: array,
				isLoading: false,
			});
		});
	}
	private loadProduct(sgeptpprodagropecuario?: number) {
		this.setState({ isLoading: true });
		this.props.viewModel.fetchSelectProduct(sgeptpprodagropecuario).then((rs) => {
			var result = rs as Array<any>;
			var array = Array<any>();

			result.forEach((item) => {
				array.push({ value: item.id, label: item.value, check: false });
			})
			this.setState({
				product: array,
				isLoading: false,
			})
		});
	}

	private loadUnitProduction() {
		this.setState({ isLoading: true });
		this.props.viewModel.fetchUnitProduction().then((rs) => {
			var result = rs as Array<any>;
			var array = Array<any>();

			result.forEach((item) => {
				array.push({ value: item.id, label: item.value, check: false });
			})

			this.setState({
				unitProduction: array,
				isLoading: false,
			});
		});
	}

	private save() {
		var json = this.state.json as any;

		if (!json['sgepprodutosagropecuarios']) {
			this.showFieldsEmpty("Produto é obrigatorio!");
			return;
		}
		if (!json["quantidade"]) {
			this.showFieldsEmpty("Quantidade é obrigatorio!");
			return;
		}
		if (!json["area"]) {
			this.showFieldsEmpty("Área é obrigatória!");
			return;
		}
		if (!json['sgepunidadeproducao']) {
			this.showFieldsEmpty("Unidade de produção é obrigatória!");
			return;
		}
		if (!json["tipo"]) {
			this.showFieldsEmpty("Tipo é obrigatório!");
			return;
		}
		if (!json["dtinicio"]) {
			this.showFieldsEmpty("Data inicial é obrigatório!");
			return;
		}
		if (!json["dtfim"]) {
			this.showFieldsEmpty("Data final é obrigatório!");
			return;
		}
		if (json["dtinicio"] > json["dtfim"]) {
			this.showFieldsEmpty("Data inicial deve ser menor que a data final!");
			return;
		}

		json["sgeppropriedade"] = this.props.idPropriedade
		this.props.viewModel.fetchSave(json).then(() => {
			toast.success("Registro incluído com sucesso!", {
				position: toast.POSITION.BOTTOM_RIGHT,
			});
			setTimeout(() => { this.props.delegate.tapDimiss(this.props.navigation); }, 1000);
		}, (e) => {
			toast.error(JSON.stringify(e), {
				position: toast.POSITION.BOTTOM_RIGHT,
			});
		}
		);

		this.setState({ isLoading: false });
	}

	private showFieldsEmpty(message: string) {
		toast.error(message, { position: toast.POSITION.BOTTOM_RIGHT, });
	}

	private loadStruct() {
		var json = this.state.json as any;
		json["sgepcadastroagropecuario"] = this.props.idPrimary;

		this.setState({ isLoading: true });
		this.props.viewModel.fetchDataOne(json).then((rs) => {
			var resultado = rs as Array<any>;
			if (resultado.length > 0) {
				this.setState({
					isLoading: false,
					json: resultado[0],
				});
				return;
			}

			this.setState({ isLoading: false });
		}, (e) => {
			toast.error(JSON.stringify(e), {
				position: toast.POSITION.BOTTOM_RIGHT,
			});
		});
	}
}
